export const URL = {
  API_BASE_URL: "https://api.cricrush.live",
   //  API_BASE_URL: "https://api.cricchamp.in",
  // API_BASE_URL: "http://localhost:3004",
// 
  // API_BASE_URL: "https://dreamslly.com:3001",
  
  getSellerList: "/apiPublic/getUserListAdmin",
  searchUser: "/apiPublic/searchUser",
  deleteseller: "/apiPublic/deleteUserAdmin",
  updatesellerByAdmin: "/apiPublic/updateUserAdmin",
  getCategoryList: "/apiPublic/playerList",
  playerListsearch: "/apiPublic/playerListsearch",
  createCategory: "/apiPublic/createPlayer",
  updateperentCate: "/apiPublic/editplayerImage",
  deleteCategory: "/apiPublic/playerDelete",
  getsubCategoryList: "/apiPublic/getTeamList",
  getTeamListsearch: "/apiPublic/getTeamListsearch",
  subcreateCategory: "/apiPublic/createTeams",
  deletesubCategory: "/apiPublic/deletesteams",
  updateperentsubCate: "/apiPublic/updateteams",
  getsubsubCategoryList: "/apiPublic/getSeriesInfo",
  getSeriesInfosearch: "/apiPublic/getSeriesInfosearch",
  subsubcreateCategory: "/apiPublic/createSeriesInfos",
  getTeamListforWinerselect: "/apiPublic/getTeamListforWinerselect",
  updateperentsubsubCate: "/apiPublic/updateserSeriesInfo",
  deletesubsubCategory: "/apiPublic/deletesSeriesInfo",
  faqslistadmin: "/apiPublic/getUmpiresList",
  createfaqs: "/apiPublic/createUmpires",
  updateFaq: "/apiPublic/updateUmpires",
  deleteFaqs: "/apiPublic/deletesUmpires",
  createPackage: "/apiPublic/createReferees",
  getPackageList: "/apiPublic/getRefereesList",
  deletePackage: "/apiPublic/deletesReferees",
  updatePackage: "/apiPublic/updateReferees",
  createBlogs: "/apiPublic/createSeriesTeam",
  getBlogList: "/apiPublic/getSeriesByTeamList",
  getSeriesByTeamListFilter: "/apiPublic/getSeriesByTeamListFilter",
  updateBlogs: "/apiPublic/updateseriesteams",
  deleteBlog: "/apiPublic/deletesSeriesteams",
  getProductList: "/apiPublic/getMatchinfoList",
  getMatchinfoListSearch: "/apiPublic/getMatchinfoListSearch",
  createProduct: "/apiPublic/creatematchInfos",
  deleteProduct: "/apiPublic/deletesmatchInfod",
  statusProduct: "/apiPublic/statusProduct",
  getmatchInfoDetails: "/apiPublic/getmatchInfoDetails",
  createTickets: "/apiPublic/createVenues",
  getTicketList: "/apiPublic/getVenuesList",
  updateVenues: "/apiPublic/updateVenues",
  deletesVenues: "/apiPublic/deletesVenues",
  updateProduct: "/apiPublic/updatesermatchInfos",

  getTransactionList: "/apiPublic/getSquadbymatchs",
  createSquadbymatchs: "/apiPublic/createSquadbymatchs",
  deletesSquadbymatchs: "/apiPublic/deletesSquadbymatchs",
  updateSquadbymatchs: "/apiPublic/updateSquadbymatchs",
  
  
  batmansCreate: "/apiPublic/batmansCreate",
  getBetmans: "/apiPublic/getBetmans",
  updatebatmans: "/apiPublic/updatebatmans",
  deleteBatsmans: "/apiPublic/deleteBatsmans",
  deletesFallwickets: "/apiPublic/deletesFallwickets",
  
  
  bolwerCreate: "/apiPublic/bolwerCreate",
  getBolwersList: "/apiPublic/getBolwersList",
  updatebolwer: "/apiPublic/updatebolwer",
  deleteBolwer: "/apiPublic/deleteBolwer",



  // points table 
  createPointstables: "/apiPublic/createPointstables",
  getPointstables: "/apiPublic/getPointstables",
  updateserPointstables: "/apiPublic/updateserPointstables",
  deletesPointstables: "/apiPublic/deletesPointstables",



  createFallwickets: "/apiPublic/createFallwickets",
  getFallwickets: "/apiPublic/getFallwickets",
  





  
  
  createPlayelevens: "/apiPublic/createPlayelevens",
  getPlayelevens: "/apiPublic/getPlayelevens",
  updatePlayelevens: "/apiPublic/updatePlayelevens",
  deletesPlayelevens: "/apiPublic/deletesPlayelevens",

  searchTicket: "/apiPublic/getVenuesListSearch",











  getPermissionsList: "/api/getPermissionsList",
  getRoleList: "/api/getRoleList",
  createRoles: "/api/createRoles",






 
  statusCategory: "/adminApi/statusCategory",
  statusSubCategory: "/adminApi/statusSubCategory",
  statusSubSubCategory: "/adminApi/statusSubSubCategory",
 
  statusSeller: "/adminApi/statusSeller",

  getsubsubcate: "/adminApi/getsubsubcate",
  getSellerListforpro: "/adminApi/getSellerListforpro",
  getcateAllList: "/adminApi/getcateAllList",



  getsubcateAllList: "/adminApi/getsubcateAllList",

  searchProduct: "/adminApi/searchProduct",
 
  searchPackage: "/adminApi/searchPackage",
 
  searchtransaction: "/adminApi/searchtransaction",
  createSellerByAdmin: "/adminApi/createSellerByAdmin",
 
  getmessageSupportList: "/adminApi/getmessageSupportList",
  createMessage: "/adminApi/createMessage",
 

  copyProduct: "/adminApi/copyProduct",
  getInvoice: "/adminApi/getInvoice",
  getsellerprofile: "/adminApi/getsellerprofile",
  createAbout: "/adminApi/createAbout",
  getPages: "/adminApi/getPages",
  createPrivacyplicys: "/adminApi/updatePage",
  getWhatsappinquire: "/adminApi/getWhatsappinquire",
  searchWhatsappinquire: "/adminApi/searchWhatsappinquire",
  getSubscription: "/adminApi/getSubscription",
  searchSubscription: "/adminApi/searchSubscription",
  getAdminBussProfile: "/adminApi/getBussProfile",
  createBussnissPro: "/adminApi/createBussnissPro",
  verifyWhatsapp: "/adminApi/verifyWhatsapp",
  updateWhatsaapNumber: "/adminApi/updateWhatsaapNumber",
  updateBussProfile: "/adminApi/updateBussProfile",
  updateBussLogo: "/adminApi/updateBussLogo",
  getInvoceDetails: "/adminApi/getInvoceDetails",
  createAllsettings: "/adminApi/createAllsettings",
  updateInvoiceLogo: "/adminApi/updateInvoiceLogo",
  updateHomeLogo: "/adminApi/updateHomeLogo",
  updateInvoiceProfile: "/adminApi/updateInvoiceProfile",
  getPackageListbuy: "/adminApi/getPackageListbuy",
  createPurchaseAdmin: "/adminApi/createPurchaseAdmin",
  getUpdateTransactionList: "/adminApi/getUpdateTransactionList",
  getEmailenquiry: "/adminApi/getEmailenquiry",
  searchEmailenquiry: "/adminApi/searchEmailenquiry",
  getpageList: "/adminApi/getpageList",
  deletePage: "/adminApi/deletePage",
  createTags: "/adminApi/createTags",
  gettagsList: "/adminApi/gettagsList",
  deletetags: "/adminApi/deletetags",
  updatetags: "/adminApi/updatetags",
  searchtags: "/adminApi/searchtags",

  createFooterPageType: "/adminApi/createFooterPageType",
  footerPageTypesDelete: "/adminApi/footerPageTypesDelete",
  footerPageTypesUpdate: "/adminApi/footerPageTypesUpdate",
  createFooterPageList: "/adminApi/createFooterPageList",
  footerPageTypes: "/adminApi/footerPageTypes",
  getpageListfooter: "/adminApi/getpageListfooter",
  footerPagepageListet: "/adminApi/footerPagepageListet",
  footerPageListsDelete: "/adminApi/footerPageListsDelete",



  productdateWise: "/adminApi/productdateWise",
  productYearWise: "/adminApi/productYearWise",
  topViewproduct: "/adminApi/topViewproduct",
  topViewproductBycate: "/adminApi/topViewproductBycate",
  topViewproductBySeller: "/adminApi/topViewproductBySeller",
  topViewAllType: "/adminApi/topViewAllType",
  createSlider: "/adminApi/createSlider",
  getsliderList: "/adminApi/getsliderList",
  updateSlider: "/adminApi/updateSlider",
  deleteSlider: "/adminApi/deleteSlider",
  createBanners: "/adminApi/createBanners",
  getBannersList: "/adminApi/getBannersList",
  updateBanners: "/adminApi/updateBanners",
  deleteBanners: "/adminApi/deleteBanners",

  createHomePop: "/adminApi/createHomePop",
  gethomePop: "/adminApi/gethomePop",
  updateStatusHomePop: "/adminApi/updateStatusHomePop",

  updateLoginLogo: "/adminApi/updateLoginLogo",
  updateRegisterLogo: "/adminApi/updateRegisterLogo",
  logoandOtherDetais: "/adminApi/logoandOtherDetais",

  //! Sponser
  createNativeSponsers:"/apiPublic/addNativeSponser",
  createBannerSponsers:"/apiPublic/addBannerSponser",
  createInitrialSponsers:"/apiPublic/addInitrialSponser",

  getNativeSponsers:"/apiPublic/getNativeSponsers",
  getBannerSponsers:"/apiPublic/getBannerSponsers",
  getInitrialSponsers:"/apiPublic/getInitrialSponsers",

  deleteNativeSponser:"/apiPublic/deleteNativeSponser",
  deleteBannerSponser:"/apiPublic/deleteBannerSponser",
  deleteInitrialSponser:"/apiPublic/deleteInitrialSponser",

  updateNativeSponser:"/apiPublic/updateNativeSponser",
  updateBannerSponser:"/apiPublic/updateBannerSponser",
  updateInitrialSponser:"/apiPublic/updateInitrialSponser",

  //? Message
  getMessage:"/apiPublic/getAllMessage",
  getMessageByID:"/apiPublic/getMessageByID", 
  addMessage:"/apiPublic/CreateMessage", //there is a similar name api 
  updateMessage:"/apiPublic/updateMessage", 
  deleteMessage:"/apiPublic/deleteMessage", 

   //Help
   addHelp:"/apiPublic/addHelp",
   getHelps:"/apiPublic/getHelp",
   deleteHelp:"/apiPublic/deleteHelp",
   updateHelp:"/apiPublic/updateHelp",
   //News
   addNews:"/apiPublic/news",
   getNews:"/apiPublic/news",
   deleteNews:"/apiPublic/news",
   updateNews:"/apiPublic/news",

};
